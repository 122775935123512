import React, { useEffect, useState, useRef } from 'react'
import "./style.css";
import { Reveal } from '../../Components/Reveal/Reveal';
import { ButtonTwo } from '../../Components/Button/Button';
import { Helmet } from 'react-helmet-async';
import emailjs from '@emailjs/browser';
export const Contact = (prop) => {
  const [message, setMessage] = useState({
    type: "",
    message: "",
    header: ""
  })
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    prop.setSelectedPage("/contact");
  }, []);


  const sumbitForm = (e) => {
    e.preventDefault();
    const frmData = new FormData(e.target);
    const email = Object.fromEntries(frmData.entries()).email;
    const subject = Object.fromEntries(frmData.entries()).subject;
    const name = Object.fromEntries(frmData.entries()).name;
    const message = Object.fromEntries(frmData.entries()).description;
    if (email !== "" && name !== "" && subject !== "" && message !== "") {

      var templateParams = {
        name, subject, email, message
      };

      emailjs.send('service_fcvz5lj', "template_mbk373s", templateParams, "x0GxPRdBO5dXGbRbv").then(function (response) {
        setMessage({
          type: "success",
          message: "Message sent successfully, Our Support team will get back to you shortly.",
          header: "Message Sent!"
        });
        e.target.reset(); // This will reset the form fields
      }, function (error) {
        setMessage({
          type: "error",
          message: "Please contact the syncafy directly via email. We are facing some issues with the form.",
          header: "Something went wrong!"
        });
      });

    } else {
      setMessage({
        type: "error",
        message: "Please make sure to fill the data before submitting.",
        header: "Missing Field!"
      });
    }
  }
  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta name='description' content="Explore our services at Syncafy, from free consultations to comprehensive solutions that bring your business to the next level." />
        <link rel='canonical' href='/services' />
      </Helmet>
      <section className='contact-container'>
        <div className='header-section-contact-page'>
          <Reveal>
            <div className='content-sizing contact-header-wrapper'>
              <h1><span className='magic-text'>Get In Touch With Syncafy</span></h1>
              <p>Can't find a time that matches your schedule? Don't worry! Send us a quick email, and we'll accommodate a time that works for you.</p>
            </div>
          </Reveal>

          <div className='form-and-contact-list'>
            <form className='contact-form' onSubmit={sumbitForm}>
              <h2>Send Us Message</h2>
              <div className='col-2-container'>
                <div class="col-1-input">
                  <input id="firstname" placeholder="Your full Name" type="text" name="name" />
                </div>
                <div className='col-1-input'>
                  <input type="email" name="email" placeholder="Your email address" />
                </div>
              </div>
              <div className='col-1-input'>
                <input type="text" name="subject" placeholder="Subject" />
              </div>

              <div className='col-1-input'>
                <textarea name="description" placeholder="Write your question here.." />
              </div>
              <ButtonTwo>Send Message</ButtonTwo>
            </form>
            <div className='contact-info-list'>
              <ul>
                <li>
                  <i class="bi bi-chat-right-text"></i>
                  <div>
                    <h3>Chat with us</h3>
                    <p>We're waiting to help you every <span className='highlight-white'>Monday-Sunday from 8 am to 11 pm</span> CT easily.</p>
                  </div>
                </li>
                <li>
                  <i class="bi bi-telephone"></i>
                  <div>
                    <h3>Give us a call</h3>
                    <p>Give us a ring at <span className='highlight-white'>(+1) 431-877-2210.</span> Every monday-friday from 8 am to 11 pm.</p>
                  </div>
                </li>
                <li>
                  <i class="bi bi-inboxes"></i>
                  <div>
                    <h3>Email Us</h3>
                    <p>Drop us an email at <span className='highlight-white'>support@syncafy.com</span> and you'll receive a reply within 24 hours.</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
