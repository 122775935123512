import React, {useEffect, useState} from 'react'
import "./style.css";
import { useNavigate } from 'react-router-dom';
import { Reveal } from '../Components/Reveal/Reveal';
import one from "../Assets/step1.png";
import two from "../Assets/2.png";
import three from "../Assets/3.png";
import video from "../Assets/video1.mp4";
import video3 from "../Assets/video3.mp4";
import {ButtonTwo} from "../Components/Button/Button";
import {Banner} from "../Components/Banner/Banner";
import { Helmet } from 'react-helmet-async';
import { BookMeeting } from '../Components/BookMeeting/BookMeeting';
import { Faq } from '../Components/Faq/Faq';
import { ServicesList } from '../Components/Services/ServicesList';
import { Reviews } from '../Components/Reviews/Reviews';

export const Home = (prop) => {
  const navigate = useNavigate();
  const [faqOpenSection, setFaqOpenSection] = useState("one");
  

  const openBox = (location) => {
    if(faqOpenSection === location) {
      setFaqOpenSection("");
    } else {
      setFaqOpenSection(location);
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });    
    prop.setSelectedPage("/");
  }, []);


  return (
    <>
      <Helmet>
        <title>Home</title>
        <meta name='description' content="With shared passion for innovation and problem-solving, we are a team of well-equipped software developers aiming to bring positive change to businesses"/>
        <link rel='canonical' href='/' />
      </Helmet>
      <Banner setIsOpen={prop.setIsOpen}/>
      <div className='page-container'>
        <div className='sass-feature'>
          <div className='content-sizing sass-feature-content'>
            <Reveal delay={.1}>
              <div className='header-section-component'>
                <h2>With Modern <span className='magic-text'>Solutions</span> </h2><h2>Here is What We Have to Offer!</h2>
                <p>Experience flawless designs and user-friendly functionality tailored to your budget, with weekly check-ins and comprehensive statistics.</p>
              </div>
            </Reveal>
            <ul className='list-sass-feature'>
              <li>
                <Reveal delay={.12}>
                  <div className='single-card-box'>
                    <div className='image-box-content-card'>
                      <img src={one} />
                    </div>
                    <h3>Budget friendly</h3>
                    <p>Flexible payment options to suit your financial needs.</p>
                  </div>
                </Reveal>
              </li>

              <li>
                <Reveal delay={.17}>
                <div className='single-card-box'>
                  <div className='image-box-content-card'>
                    <img src={two} />
                  </div>
                  <h3>Weekly check-ins</h3>
                  <p>Weekly reports to ensure timely and accurate delivery.</p>
                </div>
                </Reveal>
              </li>

              <li>
                <Reveal delay={.2}> 
                  <div className='single-card-box'>
                    <div className='image-box-content-card'>
                      <img src={three} />
                    </div>
                    <h3>Statistics</h3>
                    <p>Data shapes strategy; insights drive our approach.</p>
                  </div>
                </Reveal>
              </li>
            </ul>
          </div>
        </div>

        <div className='benefits-list'>
          <div className='content-sizing benefits-list-wrapper'>
            <div className='left-side-content'>
              <Reveal>
                <div className='left-side-benefits-header'>
                  <h2>Digital Precision. <span className='magic-text'>Your SaaS</span> Begins Here! </h2>
                  <p>Experience smooth design and effortless functionality, backed by round-the-clock support. Transition your worries to us and unwind while you sit back and relax.</p>
                </div>
              </Reveal>
              <Reveal delay={.15}>
              <ul className='list-of-benefits'>
                <li className='single-of-benefits'>
                  <i className="bi bi-gear"></i>
                  <h3>Easy To Manage</h3>
                  <p>Effortlessly streamline your operations with no micromanagement needed.</p>
                </li>
                <li className='single-of-benefits'>
                  <i className="bi bi-bezier2"></i>
                  <h3>Collaborate securely</h3>
                  <p>Protect Your Team and Data with Our Secure Collaboration Tools</p>
                </li>
                <li className='single-of-benefits'>
                  <i className="bi bi-speedometer2"></i>
                  <h3>Real time analysis</h3>
                  <p>Instant Insights: Harness Real-Time Analysis for Informed Decision-Making</p>
                </li>
                <li className='single-of-benefits'>
                  <i className="bi bi-chat"></i>
                  <h3>24/7 Support</h3>
                  <p>Round-the-Clock Assistance: Day or Night, We're Here for You</p>
                </li>
              </ul>
              </Reveal>
            </div>
            <Reveal>
              <video width="650" height="500"  autoPlay loop muted controls = ""  className='video-one video-main'>
                <source src={video} type="video/mp4"/>
              </video>
            </Reveal>
            
            
          </div>

        </div>

        <div className='benefits-list'>
          <div className='content-sizing benefits-list-wrapper reverse-flex'>
            <Reveal>
              <video width="650" height="500"  autoPlay loop muted controls = ""  className='video-two video-main'>
                <source src={video3} type="video/mp4"/>
              </video>
            </Reveal>
            <div className='left-side-content'>
              <Reveal>
                <div className='left-side-benefits-header'>
                  <h2>Crafting Powerful Software Solutions for <span className='magic-text'>Lasting Results</span> </h2>
                  <p>Our streamlined process eliminates intimidation with four simple steps. Enjoy a hassle-free journey towards success.</p>
                </div>
              </Reveal>
              <Reveal delay={.15}>
                <ul className='innovation-list'>
                  <li><i className="bi bi-check-lg"></i> Explore: Discover your options with ease and get free consultation</li>
                  <li><i className="bi bi-check-lg"></i> Customize: Tailor your solution to fit your needs.</li>
                  <li><i className="bi bi-check-lg"></i> Implement: Watch your vision come to life effortlessly.</li>
                  <li><i className="bi bi-check-lg"></i> Thrive: Enjoy the benefits of seamless integration.</li>
                </ul>
              </Reveal>
              <Reveal delay={.2}>
                <ButtonTwo onClick={() => navigate('/about-us')}>Read More</ButtonTwo>
              </Reveal>
            </div>
          </div>
        </div>

        <ServicesList />

        
        <BookMeeting setIsOpen={prop.setIsOpen}/>

        <Reviews />

        <Faq />
      </div>
    </>
  )
}
