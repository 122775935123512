import React, {useEffect} from 'react'
import { Reveal } from '../../Components/Reveal/Reveal'
import "./style.css";
import { Helmet } from 'react-helmet-async';
import { BookMeeting } from '../../Components/BookMeeting/BookMeeting';
import { ServicesList } from '../../Components/Services/ServicesList';
import { Reviews } from '../../Components/Reviews/Reviews';

export const Services = (prop) => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    prop.setSelectedPage("/services");
  }, []);

  return (
    <>
      <Helmet>
        <title>Services</title>
        <meta name='description' content="Explore our services at Syncafy, from free consultations to comprehensive solutions that bring your business to the next level."/>
        <link rel='canonical' href='/services' />
      </Helmet>
      <section className='about-us-container'>
        <div className='header-section-about-page'>
          <Reveal>
            <div className='content-sizing about-header-wrapper'>
              <h1>Experience The Thrill With <br/> Our <span className='magic-text'>Software Solutions</span></h1>
              <p>Explore the interconnected connections and valuable insights that define our approach. Discover how our team's expertise and collaborative efforts create innovative solutions tailored to your needs.</p>
            </div>
          </Reveal>
        </div>
      </section>

      <aside className='about-info-container service-page'>
        <ServicesList HideHeader={true}/>
        <BookMeeting setIsOpen={prop.setIsOpen}/>
        <Reviews />
      </aside>
    </>
  )
}
