import React from 'react'
import "./style.css";
import { ButtonTwo } from '../Button/Button';
import bannerDashboard from "../../Assets/dashboard.png";
import shape from "../../Assets/shape.png";
import shape2 from "../../Assets/shape2.png";
import {Reveal} from "../../Components/Reveal/Reveal";
import logo4 from "../../Assets/logo/4.png";
import logo3 from "../../Assets/logo/3.png";
import logo2 from "../../Assets/logo/2.png";
import logo1 from "../../Assets/logo/1.png";


export const Banner = (prop) => {
  return (
    <section className='banner-home'>
      <section className='content-sizing banner-wrapper'>
        <>
          <Reveal delay={.1}>
            <div className='tag-content-banner'>
              <span><i className="bi bi-stars"></i> New</span>
              <p>Syncafy specializing in AI integration <i className="bi bi-arrow-right"></i></p>
            </div>
          </Reveal>

          <Reveal delay={.15}>
            <div className='banner-content-text'>
            
              <div className='left-banner-text'>
                {/* can change the your problems part with something along the way Empowering Your business and problems with our solutions  */}
                {/* <h1><span className='magic-text'>All in One</span>Software Solutions to Grow <span className='magic-text'>Your business</span></h1> */}
                <h1> <span className='magic-text'>All in One</span> Solutions to Elevate <span className='magic-text'>Your Business</span> </h1>
              </div>
            
              <div className='right-banner-text'>
                {/* We provide seamless, scalable, and cost-effective software. This allows your business to take advantage of the latest software at no cost. */}
                <p>Experience seamless, scalable, and budget-friendly software solutions. Elevate your business with the latest technology, without breaking the bank.</p>
                <div className='start-contact-banner'>
                  <ButtonTwo onClick={() => prop.setIsOpen(true)}>Get Started</ButtonTwo>
                  <div className='contact-section-banner'>
                    <i className="bi bi-headset"></i>
                    <div>
                      <h3>Reach out</h3>
                      <p>+1 (431)-877-2210</p>
                    </div>
                  </div>
                </div>
              </div>
            
          </div>
          </Reveal>
        </>

        <Reveal delay={.2}>
          <div className='center-db'>
            <img src={shape} className='background-shape shape-left'/>
            <section className='dashboard-image-banner'>
              <img src={bannerDashboard} />
            </section>
            <img src={shape2} className='background-shape shape-right'/>
          </div>
        </Reveal>

        <div className='company-logo-container'>
          <div className='content-sizing company-logo'>
            <Reveal>
              <h2 className='header-text-company'>Entrusted by <span>100+</span> businesses to increase sales and guarantee success.</h2>
            </Reveal>
            <ul className='logo-list'>
              <li><img src={logo1}/></li>
              <li><img src={logo2}/></li>
              <li><img src={logo3}/></li>
              <li><img src={logo4}/></li>
            </ul>
          </div>
        </div>
      </section>
    </section>
  )
}
