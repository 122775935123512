import React, {useMemo} from 'react'
import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
import { Outlet } from 'react-router-dom'


export const LandingPage = (prop) => {

  const MemoizedHeader = useMemo(() => React.memo(Header), [prop.selectedPage]);
  const MemoizedFooter = useMemo(() => React.memo(Footer), []);


  return (
    <>
      <div className='main-section'>
        <MemoizedHeader selectedPage={prop.selectedPage} setSelectedPage={prop.setSelectedPage} setIsOpen={prop.setIsOpen}/>
        <Outlet/>
        <MemoizedFooter/>
      </div>
    </>
  )
}