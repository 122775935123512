import React from 'react'
import { Reveal } from '../../Components/Reveal/Reveal';


export const ServicesList = (prop) => {
  return (
    <div className='list-of-services'>
          <div className='content-sizing services-wrapper'>
            {!prop.HideHeader && <Reveal>
              <div className='service-section-header'>
                <h2>Experience The Thrill With Our <span className='magic-text'>Software Solutions</span></h2>
                <p>Explore a suite of powerful features designed to elevate your software experience. From user authentication to advanced AI integrations, our software offers everything you need to streamline operations and drive success.</p>
              </div>
            </Reveal>}

            <Reveal delay={.2}>
              <ul className='list-service-ul'>
                <li>
                  <i className="bi bi-person"></i>
                  <h3>User Authentication</h3>
                  <p>Safeguard your system with multi-factor authentication, ensuring only authorized users gain access.</p>
                </li>
                <li>
                  <i className="bi bi-cloud"></i>
                  <h3>Cloud-Based Storage</h3>
                  <p> Enjoy unlimited storage capacity and easy scalability with our secure cloud storage solutions.</p>
                </li>
                <li>
                  <i className="bi bi-send-plus"></i>
                  <h3>Integration Capabilities</h3>
                  <p>Seamlessly connect with other software systems to streamline workflows and maximize efficiency.</p>
                </li>
                <li>
                <i className="bi bi-clock-history"></i>
                  <h3>Real-Time Collaboration</h3>
                  <p>Foster teamwork and productivity with instant communication and collaboration tools.</p>
                </li>
                <li>
                <i className="bi bi-graph-up"></i>
                  <h3>Analytics and Reporting</h3>
                  <p>Gain valuable insights into your data with advanced analytics and reporting features.</p>
                </li>
                <li>
                <i className="bi bi-grid-1x2"></i>
                  <h3>Mobile Accessibility</h3>
                  <p>Access your software from anywhere, anytime, with responsive mobile interfaces.</p>
                </li>
                <li>
                <i className="bi bi-hdd-stack"></i>
                  <h3>Version Control</h3>
                  <p>Keep track of changes and manage multiple versions of your software or documents with ease.</p>
                </li>
                <li>
                <i className="bi bi-inboxes-fill"></i>
                  <h3>Audit Trails</h3>
                  <p>Maintain accountability and track user activity with comprehensive audit trail functionality.</p>
                </li>
                <li>
                <i className="bi bi-receipt-cutoff"></i>
                  <h3>Billing and Invoicing</h3>
                  <p> Maintain accountability and track user activity with comprehensive audit trail functionality.</p>
                </li>
                <li>
                <i className="bi bi-arrow-repeat"></i>
                  <h3>Data Migration</h3>
                  <p>Seamlessly transfer your data from one system to another, ensuring continuity and integrity.</p>
                </li>
                <li>
                <i className="bi bi-robot"></i>
                  <h3>AI Integrations</h3>
                  <p> Leverage the power of artificial intelligence to enhance your software's capabilities</p>
                </li>
                <li className='center-content'>
                
                  <h3>Features Coming Soon</h3>
                </li>
                
              </ul>
            </Reveal>
          </div>
        </div>
  )
}
