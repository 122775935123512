import React, {useState, useEffect, useMemo, useCallback} from 'react'
import "./App.css";
import { Home } from './Pages/Home';
import { LandingPage } from './Components/LandingPage/LandingPage';
import { Routes, Route } from "react-router-dom";
import { About } from './Pages/About/About';
import { Contact } from './Pages/Contact/Contact';
import  Error404 from "./Pages/Error404/Error404";
import { Services } from "./Pages/Services/Services";
import { PopupModal } from "react-calendly";

const App = () => {
  const [selectedPage, setSelectedPage] = useState("/");
  const [isVisible, setIsVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    const scrollTop = window.scrollY || window.pageYOffSet;

    if (scrollTop > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }

  useEffect(() => {
    scrollToTop();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const buttonClass = useMemo(() => {
    return isVisible ? 'back-to-top show-back-to-top' : 'back-to-top';
  }, [isVisible]);

  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage selectedPage={selectedPage} setSelectedPage={setSelectedPage} setIsOpen={setIsOpen}/>}>
          <Route index element={<Home setSelectedPage={setSelectedPage} setIsOpen={setIsOpen}/>} />
          <Route path="about-us" element={<About setSelectedPage={setSelectedPage} setIsOpen={setIsOpen}/>} />
          <Route path="services" element={<Services setSelectedPage={setSelectedPage} setIsOpen={setIsOpen}/>} />
          <Route path="contact" element={<Contact setSelectedPage={setSelectedPage} />} />
          <Route path="*" element={<Error404 />} />
        </Route>

      </Routes>

      <button className={buttonClass} onClick={scrollToTop}>
        <i className="bi bi-chevron-up"></i>
      </button>

      <PopupModal
        url="https://calendly.com/syncafy/free-30-minutes-consultation"
        open={isOpen}
        rootElement={document.getElementById("root")}
        onModalClose={() => setIsOpen(false)}
      />
      {/* <button className={isVisible ? "back-to-top show-back-to-top" : "back-to-top" } onClick={scrollToTop}><i className="bi bi-chevron-up"></i></button> */}
    </>
  )
}

export default App;