import React, {useEffect, useState, useRef} from 'react'
import "./style.css";
import { Reveal } from '../../Components/Reveal/Reveal';
import { CountUp } from 'use-count-up';
import {useInView} from "framer-motion";
import { ButtonTwo } from '../../Components/Button/Button';
import meeting from "../../Assets/meeting.png";
import { Helmet } from 'react-helmet-async';
import { BookMeeting } from '../../Components/BookMeeting/BookMeeting';
import { Faq } from '../../Components/Faq/Faq';


export const About = (prop) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {once: true});
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    if(isInView) {
      setIsCounting(true);
    }
  }, [isInView]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    prop.setSelectedPage("/about");
  }, []);

  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta name='description' content="Join us on our journey and discover more about how we're leveraging its potential to drive innovation and positive change in businesses worldwide."/>
        <link rel='canonical' href='/about-us' />

      </Helmet>
      <section className='about-us-container'>
        <div className='header-section-about-page'>
          <Reveal>
            <div className='content-sizing about-header-wrapper'>
              <h1>Unlocking Synergies<br/> <span className='magic-text'>Exploring Linked Insights</span></h1>
              <p>Explore the interconnected connections and valuable insights that define our approach. Discover how our team's expertise and collaborative efforts create innovative solutions tailored to your needs.</p>
            </div>
          </Reveal>
        </div>
      </section>

      <aside className='about-info-container'>
        <div className='content-sizing about-info-container-section'>
          <h2>Our Story</h2>
          <Reveal>
            <div className='about-paragraph-page'>
              <p>"The story of our journey <span className='highlight-white'>began</span> with four university students from diverse backgrounds, each bringing <span className='highlight-white'>unique skills</span> and perspectives to the table. Bonded by a common <span className='highlight-white'>goal </span>of making a <span className='highlight-white'>meaningful impact</span> in the software industry, we spent countless late nights brainstorming ideas and <span className='highlight-white'>refining</span> our vision."</p>
              <p><span className='highlight-white'>Driven </span> by our shared passion for innovation and <span className='highlight-white'>problem-solving</span>, we embarked on a mission to revolutionize the way businesses <span className='highlight-white'>approach</span> digital transformation. With unwavering <span className='highlight-white'>determination</span> and a <span className='highlight-white'>hunger</span> for <span className='highlight-white'>success</span>, we poured our <span className='highlight-white'>hearts</span> and souls into building our agency from the ground up.</p>
              <p>Along the way, we faced numerous <span className='highlight-white'>challenges</span> and setbacks, but we never lost <span className='highlight-white'>sight</span> of our ultimate goal. Through <span className='highlight-white'>perseverance</span> and resilience, we <span className='highlight-white'>overcame</span> every <span className='highlight-white'>obstacle</span> that came our way, emerging <span className='highlight-white'>stronger</span> and more <span className='highlight-white'>determined</span> than ever before.</p>
              <p>Today, as we look back on our <span className='highlight-white'>journey</span>, we are <span className='highlight-white'>proud</span> of how far we've come. From humble beginnings to a <span className='highlight-white'>thriving SAAS</span> agency, we continue to push the boundaries of what's possible, fueled by our <span className='highlight-white'>passion</span> for <span className='highlight-white'>innovation</span> and our <span className='highlight-white'>unwavering</span> commitment to our <span className='highlight-white'>clients' success.</span></p>
            </div>
          </Reveal>
          <Reveal>
            <div className='single-about-para'>
              <h3>Our Vision</h3>
              <p>Empower businesses worldwide with groundbreakingSAAS innovations, spearheading transformative solutions that redefine operational excellence and drive positive change.</p>
            </div>
          </Reveal>
          <Reveal>
            <div className='single-about-para'>
              <h3>Our Mission</h3>
              <p>Our mission is to revolutionize the SAAS industry by developing cutting-edge technology solutions that redefine business operations globally. We strive to lead the way in innovation, empowering businesses to thrive in the digital age and driving positive change across all industries.</p>
            </div>
          </Reveal>
          <ul className='about-stats' ref={ref}>
            <li>
              <h4><CountUp isCounting={isCounting} end={12} duration={1.5} /> <span>+</span></h4>
              <p>Year Experiences</p>
            </li>
            <li>
              <h4><CountUp isCounting={isCounting} end={99} duration={1.5} /> <span>%</span></h4>
              <p>Client Satisfaction</p>
            </li>
            <li>
              <h4><CountUp isCounting={isCounting} end={100} duration={1.5} /> <span>+</span></h4>
              <p>Happy Clients</p>
            </li>
          </ul>
        </div>

        <BookMeeting setIsOpen={prop.setIsOpen}/>
        <Faq />
      </aside>
    </>
  )
}
