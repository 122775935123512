import React, { useEffect, useState } from 'react'
import "./style.css";
import { ButtonOne, ButtonTwo } from '../Button/Button';
import logo from "../../Assets/logo.png";
import logoText from "../../Assets/logo-text.png";
import { Link, useNavigate } from 'react-router-dom';

const THRESHOLD = 70; // Adjust this threshold value as needed
export const Header = (prop) => {
  const navigate = useNavigate();
  const [showNavBar, setShowNavBar] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const scrollingDown = prevScrollPos > currentScrollPos;
      setShowNavBar(currentScrollPos === 0 || scrollingDown || currentScrollPos < THRESHOLD);

      setPrevScrollPos(currentScrollPos);

      // setShowNavBar(prevScrollPos > currentScrollPos || currentScrollPos === 0);
      // setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);


  return (
    <header className={prevScrollPos > 90 ? "scroll-back" : "scroll-back-hide"} style={{ position: 'fixed', top: showNavBar ? '0' : '-100px', width: '100%' }}>
      <div className='content-sizing header-wrapper'>
        <div className='logo-box'>
          <div className='icon-box-logo'>
            <img src={logo} width={28} />
          </div>
          <img src={logoText} width={100} />
        </div>
        <nav>
          <ul>
            <li><Link to="/" className={prop.selectedPage === "/" ? "active-menu-itm" : ""}>Home</Link></li>
            <li><Link to="/about-us" className={prop.selectedPage === "/about" ? "active-menu-itm" : ""}>About</Link></li>
            <li><Link to="/services" className={prop.selectedPage === "/services" ? "active-menu-itm" : ""}>Services</Link></li>
            <li><Link to="/contact" className={prop.selectedPage === "/portfolio" ? "active-menu-itm" : ""}>Contact</Link></li>

          </ul>
        </nav>
        {/* <ButtonOne className="talk-header-button"> Let's Talk<i className="bi bi-arrow-right"></i></ButtonOne> */}
        <ButtonOne className="talk-header-button" onClick={() => prop.setIsOpen(true)}><span className='contact-button'>Let's Talk</span><i className="bi bi-arrow-right"></i></ButtonOne>
        <button className="menu-button-header"><i className="bi bi-list"></i></button>
      </div>
    </header >
  )
}
