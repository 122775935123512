import React from 'react'
import logo from "../../Assets/logo.png";
import logoText from "../../Assets/logo-text.png";
import "./style.css";

export const Footer = () => {
  return (
    <footer>
      <div className='content-sizing footer-container'>
        <div className='top-box-logo-bio'>
          <div className='copy-logo'>
            <div className='logo-box'>
              <div className='icon-box-logo'>
                <img src={logo} width={28}/>
              </div>
              <img src={logoText}  width={100}/>
            </div>
            <p>© 2023 <span className='bold-text'>Syncafy</span>. All rights reserved.</p>
          </div>
            {/* <p></p> */}
        </div>

        <div className='footer-bottom'>
          <div className='contact-info'>
            <div>
              <h3>Address</h3>
              <p>49 Rustic Oak Trail, Ayr, Ontario N0B1E0 </p>
            </div>

            <div>
              <h3>Phone</h3>
              <p>+1 (431)-877-2210</p>
            </div>

            <div>
              <h3>Email</h3>
              <p>support@syncafy.com</p>
            </div>
          </div>

          <div className='quick-link-filter'>
            <div>
              <h3>Company</h3>
              <ul>
                <li>Home</li>
                <li>About</li>
                <li>Service</li>
                <li>Contact</li>
              </ul>
            </div>
            <div>
              <h3>Services</h3>
              <ul>
                <li>About</li>
                <li>Pricing</li>
                <li>License</li>
                <li>Company</li>
                <li>Services</li>
                <li>Features</li>
              </ul>
            </div>
            <div>
              <h3>Legal</h3>
              <ul>
                <li>Privacy Policy</li>
                <li>Terms of Service</li>
              </ul>
            </div>
          </div>
        </div>

        {/* <div className='social-media'>
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div> */}
      </div>
    </footer>
  )
}
