import React from 'react'
import { Reveal } from '../Reveal/Reveal'
export const Reviews = () => {
  return (
    <div className='user-rating'>
          <div className='content-sizing user-rating-content'>
            <Reveal delay={.1}>
              <div className='service-section-header'>
                <h2>User Ratings <br/><span className='magic-text'>Beyond Expectations</span></h2>
                <p>Discover why users are raving about our software, as our exceptional features and unparalleled performance consistently exceed expectations.</p>
              </div>
            </Reveal>

            <Reveal delay={.15}>
              <ul className='list-of-review'>
                <li>
                  <div className='profile-header-review'>
                    <div className='profile-header-text'>
                      <h3>Will Chen</h3>
                      
                    </div>
                  </div>
                  <p>"Syncafy has made a huge difference for us. Their cloud storage system has made it easy for us to handle our data and work together smoothly. The user authentication feature adds an extra layer of security that we really like. All in all, Syncafy has gone beyond what we expected and is now a big part of how we do things every day."</p>
                </li>
                <li>
                  <div className='profile-header-review'>
                    <div className='profile-header-text'>
                      <h3>Akash Patel</h3>
                    
                    </div>
                  </div>
                  <p>"Syncafy has been amazing for our food website. It's so easy to use and doesn't need much managing. It helps us work together smoothly, even when we're not in the same place. Syncafy has really impressed us and is now a must-have for our business."</p>
                </li>
                <li>
                  <div className='profile-header-review'>
                    <div className='profile-header-text'>
                      <h3>Atsuhiro Shirakaki</h3>
                      
                    </div>
                  </div>
                  <p>"Syncafy made building our game website super easy. It's simple to use and didn't need much work to run. We could easily connect it with other tools we use, and it helped our team work together smoothly. We're really happy with Syncafy - it's made our job a lot simpler."</p>
                </li>
                
                <li>
                  <div className='profile-header-review'>
                    <div className='profile-header-text'>
                      <h3>Seth Cheung</h3>
                      <p></p>
                    </div>
                  </div>
                  <p>"Syncafy's CRM solution has been a game-changer for our dealership. It's simple, efficient, and helps us stay on top of our customer relationships like never before. Highly recommended!"</p>
                </li>
                <li>
                  <div className='profile-header-review'>
                    <div className='profile-header-text'>
                      <h3>Sarah Rudy</h3>
                      
                    </div>
                  </div>
                  <p>"Working with Syncafy has been a game-changer for our business. Their software solutions not only streamlined our processes but also elevated our efficiency to new heights. The team at Syncafy was incredibly attentive to our needs, providing personalized support every step of the way. Thanks to their innovative approach and dedication to excellence, we've been able to achieve remarkable results. I can't recommend Syncafy highly enough!"</p>
                </li>
                <li>
                  <div className='profile-header-review'>
                    <div className='profile-header-text'>
                      <h3>Rohan Mehata</h3>
                    </div>
                  </div>
                  <p>"From day one, Syncafy felt like a partner rather than just a service provider. The ongoing updates and improvements show their dedication to staying ahead in the industry."</p>
                </li>
              </ul>
            </Reveal>
          </div>
        </div>
  )
}
