import React from 'react'
import "./style.css";
import { ButtonTwo } from '../../Components/Button/Button';



const Error404 = () => {

  return (
    <div className='main-error-box'>

        <div className='error-box-one'>
          <span className='magic-text'>404</span>
        </div>

        <div className='error-box-two'>
           <h2 className='error-box-two-h2'>Page Not Found!</h2>
           <p>Oops! The Page You're Looking For Was Not Found.</p>
           <div><ButtonTwo> <i className="bi bi-house-fill"></i> Back To Home</ButtonTwo></div>
        </div>
    </div>
  )
}

export default Error404;

