import React from 'react'
import { Reveal } from '../Reveal/Reveal';
import { ButtonTwo } from '../Button/Button';
import meeting from "../../Assets/meeting.png";

export const BookMeeting = (prop) => {
  return (
    <div className='book-meeting'>
      <div className='content-sizing book-meeting-container'>
        <Reveal delay={.2}>
          <div className='left-side-book-meeting'>
            <h1>Ready To Join ? <br/> Get <span className='magic-text'>Free Consultation</span></h1>
            <p>Schedule your free consultation today and discover how we can help you achieve your goals.</p>
            <ButtonTwo onClick={() => prop.setIsOpen(true)}>Book Now</ButtonTwo>
          </div>
        </Reveal>

        <Reveal delay={.2}>
          <img src={meeting} width={500}/>
        </Reveal>
      </div>
    </div>
  )
}
