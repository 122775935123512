import React, {useState} from 'react'
import { ButtonTwo } from '../Button/Button';
import { Reveal } from '../Reveal/Reveal';
import { Link, useNavigate } from 'react-router-dom';

export const Faq = () => {
  const navigate = useNavigate();
  const [faqOpenSection, setFaqOpenSection] = useState("one");

  const openBox = (location) => {
    if(faqOpenSection === location) {
      setFaqOpenSection("");
    } else {
      setFaqOpenSection(location);
    }
  }

  return (
    <div className='faq-main-wrapper'>
          <div className='content-sizing faq-container'>
            <Reveal delay={.15}>
              <div className='service-section-header'>
                <h2 className='full-width'>Frequently Asked <span className='magic-text'>Questions</span></h2>
                <p>No Question or Concern Left Unanswered With Us </p>
              </div>
            </Reveal>

            <Reveal delay={.2}>
              <ul className='faq-list-container-main-page'>
                <li className={faqOpenSection === "one" ? "active faq" : "faq"} onClick={() => openBox("one")}>
                  <div className="question">
                    <h3>What security measures does your SAAS solution have in place to protect user data?</h3>
                    <i className="bi bi-chevron-down"></i>
                  </div> 
                  <div className="answer">
                    <p>At Syncafy, we take the security of user data very seriously. Our SAAS solution employs industry-standard encryption protocols to ensure that all data transmission and storage are secure. We also implement robust access control measures to restrict access to sensitive information only to authorized personnel. Regular security audits and updates are conducted to identify and address any potential vulnerabilities, ensuring that your data remains protected at all times.</p>
                  </div>
                </li>
                <li className={faqOpenSection === "two" ? "active faq" : "faq"} onClick={() => openBox("two")}>
                  <div className="question">
                    <h3>Can your SAAS platform integrate with other software systems we use?</h3>
                    <i className="bi bi-chevron-down"></i>
                  </div> 
                  <div className="answer">
                    <p>Yes, our SAAS platform is designed to seamlessly integrate with a wide range of other software systems commonly used by businesses. Whether it's customer relationship management (CRM) software, accounting tools, or project management platforms, our flexible integration capabilities enable smooth data exchange and workflow automation across multiple systems. Our team of integration specialists is available to assist with the setup and customization of integrations to meet your specific needs.</p>
                  </div>
                </li>
                <li className={faqOpenSection === "three" ? "active faq" : "faq"} onClick={() => openBox("three")}>
                  <div className="question">
                    <h3>How does your SAAS solution ensure scalability as our business grows?</h3>
                    <i className="bi bi-chevron-down"></i>
                  </div> 
                  <div className="answer">
                    <p> Syncafy's SAAS solution is built on a scalable architecture that allows it to grow with your business. Whether you're a small startup or a large enterprise, our platform can accommodate your changing needs and increasing demands without compromising performance or reliability. Our infrastructure is designed to handle high volumes of data and traffic, ensuring a seamless user experience even as your business expands.</p>
                  </div>
                </li>
                <li className={faqOpenSection === "four" ? "active faq" : "faq"} onClick={() => openBox("four")}>
                  <div className="question">
                    <h3>What level of customer support and maintenance is included with your SAAS subscription?</h3>
                    <i className="bi bi-chevron-down"></i>
                  </div> 
                  <div className="answer">
                    <p>As a Syncafy subscriber, you'll receive excellent customer support 24/7. Our team is here to help with any questions or technical issues you may have. Plus, we regularly update and maintain our platform for your security and performance. We're committed to your satisfaction and success with our SAAS solution.</p>
                  </div>
                </li>
              </ul>
            </Reveal>

            <Reveal delay={.15}>
              <div className='faq-question'>
                <h3>Still Have Questions?</h3>
                <p>Connect With Our Support Team Directly</p>
                <ButtonTwo onClick={() => navigate("/contact")}>Contact Us</ButtonTwo>
              </div>
            </Reveal>
          </div>
        </div>
  )
}
