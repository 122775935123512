import React from 'react'
import "./style.css";

export const ButtonOne = ({children, className, onClick}) => {
  return (
    <button className={`button-standard ${className}`} onClick={onClick}>{children}</button>
  )
}


export const ButtonTwo = ({children, onClick}) => {
  return (
    <button className='button-blue' onClick={onClick}>{children}</button>
  )
}
